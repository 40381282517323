import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AsyncComponent from '@/utils/AsyncComponent'

const h5BuyCourse = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/index'))
const h5BuyCourseLogin = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/login'))
const confirmOrder = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/confirmOrder'))
const unPay = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/unPay'))
const lessonDetail = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/lessonDetail'))
const paySuccess = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/paySuccess'))
const cancatPlanner = AsyncComponent(()=>import('@/pages/TobClient/h5BuyCourse/cancatPlanner'))

export default ()=>{
    return (
        <Switch>
            <Route path="/h5BuyCourse" component={h5BuyCourse} exact/>
            <Route path="/h5BuyCourseLogin" component={h5BuyCourseLogin} exact/>
            <Route path="/h5BuyCourse/confirmOrder" component={confirmOrder} exact/>
            <Route path="/h5BuyCourse/unPay" component={unPay} exact/>
            <Route path="/h5BuyCourse/lessonDetail" component={lessonDetail} exact/>
            
            <Route path="/h5BuyCourse/paySuccess" component={paySuccess} exact/>

            <Route path="/h5BuyCourse/cancatPlanner" component={cancatPlanner} exact/>
        </Switch>
    )
}