import {combineReducers} from 'redux'
import assessmentReducer from '@/pages/Assessment/reducer'
import outSchoolReducer from '@/pages/OutSchool/reducer'
import twelveNignReducer from '@/pages/TwelveNign/reducer'
import experienceLessonReducer from '@/pages/GetExperienceClass/reducer'
import vipH5Reducer from '@/pages/VipH5/reducer'

export default combineReducers({
    assessmentReducer,
    outSchoolReducer,
    twelveNignReducer,
    experienceLessonReducer,
    vipH5Reducer
})