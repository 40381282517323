import React from "react";
import { Switch, Route } from "react-router-dom";
import AsyncComponent from "@/utils/AsyncComponent";

const h5PictureBookLogin = AsyncComponent(()=>import("@/pages/TobClient/h5PictureBook/login"));
const h5PictureBook = AsyncComponent(()=>import("@/pages/TobClient/h5PictureBook/index"));
const h5PictureBookDetails = AsyncComponent(()=>import("@/pages/TobClient/h5PictureBook/details"));

export default () => {
    return (
        <Switch>
            <Route path="/h5PictureBookLogin" component={h5PictureBookLogin} exact />
            <Route path="/h5PictureBook" component={h5PictureBook} exact />
            <Route path="/h5PictureBookDetails" component={h5PictureBookDetails} exact />
        </Switch>
    )
}