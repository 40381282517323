
import {fromJS} from 'immutable'
const defaultState = fromJS({
    openId:'',
    isLogin:false,
})


const reducers = {
    SETISLOGIN(state, isLogin){
        return state.set('isLogin', isLogin)
    },
    SETOPENID(state, id){
        return state.set('openId', id)
    }
}
const twelveNignReducer = (state =defaultState, action={} ) =>{
    const {type, param} = action
    if(reducers[type]){
        return reducers[type](state, param)
    }else{
        return state
    }
}

export default twelveNignReducer