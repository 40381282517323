import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AsyncComponent from '@/utils/AsyncComponent'

const BrandIntroduce = AsyncComponent(()=>import('@/pages/SimpleActivity/brandH5/index'))
const GrowthEBook = AsyncComponent(()=>import('@/pages/SimpleActivity/growthEBook/index'))
const GrowthEBookDEtails = AsyncComponent(()=>import('@/pages/SimpleActivity/growthEBook/details'))

export default ()=>{
    return (
        <Switch>
            <Route path="/brandIntroduce" component={BrandIntroduce} exact/>
            <Route path="/growthEBook" component={GrowthEBook} exact />
            <Route path="/growthEBookDetails" component={GrowthEBookDEtails} exact />
        </Switch>
    )
}