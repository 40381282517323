import {fromJS} from 'immutable'
const defaultState = fromJS({
    openId:'',
    channelId:'',
    lessonType:'',
    loginPhone:''
})

const reducers ={
    SETOPENID(state, id){
        return state.set('openId', id)
    },
    SETCHANNELID(state, id){
        return state.set('channelId', id)
    },
    SETLESSONTYPE(state, id){
        return state.set('lessonType', id)
    },
    SETLOGIN(state, id){
        return state.set('loginPhone', id)
    }
}

const outSchoolReducer = (state =defaultState, action={} ) =>{
    const {type, param} = action
    if(reducers[type]){
        return reducers[type](state, param)
    }else{
        return state
    }
}

export default outSchoolReducer