import {fromJS} from 'immutable'
const defaultState = fromJS({
    payInfo:{},
    openId:''
})

const reducer = {
    SETPAYINFO(state, questions){
        return state.set('payInfo', questions)
    },
    SETOPENID(state, data){
        return state.set('openId', data)
    }
}

const experienceLessonReducer = (state = defaultState, action={})=>{
    const {type, param} = action
    if(reducer[type]){
        return reducer[type](state, param)
    }else{
        return state
    }
    
}
export default experienceLessonReducer