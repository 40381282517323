import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AsyncComponent from '@/utils/AsyncComponent'
import SimpleActivityRoute from './router/simpleActivity'
import H5Sell from './router/h5sell'
import H5Picture from './router/h5picture'

/*改用按需加载的方式引入，减少首屏js加载时长*/
const Agreement = AsyncComponent(()=>import('@/pages/SimpleActivity/Agreement'))
const PictureBook = AsyncComponent(()=>import('@/pages/SimpleActivity/pictureBook'))
const BClientPictureBook = AsyncComponent(()=>import('@/pages/TobClient/drawPicture/pictureBook'))
const Privacy = AsyncComponent(()=>import('@/pages/SimpleActivity/Privacy'))
const GardenPrivacy = AsyncComponent(()=>import('@/pages/SimpleActivity/garden/privacy'))
const GardenIntroduce = AsyncComponent(()=>import('@/pages/SimpleActivity/garden/instruction/useIntroduction'))
const GardenAgreement = AsyncComponent(()=>import('@/pages/SimpleActivity/garden/userAgreement'))
const ParadisePrivacy = AsyncComponent(()=>import('@/pages/SimpleActivity/paradise/privacy'))
const ParadiseIntroduce = AsyncComponent(()=>import('@/pages/SimpleActivity/paradise/instruction/useIntroduction'))
const ParadiseAgreement = AsyncComponent(()=>import('@/pages/SimpleActivity/paradise/userAgreement'))
const MySchool = AsyncComponent(()=>import('@/pages/SimpleActivity/paradise/mySchool'))
const StudentDownload = AsyncComponent(()=>import('@/pages/SimpleActivity/QrcodeDownload/studentDownload'))
const TeacherDownload = AsyncComponent(()=>import('@/pages/SimpleActivity/QrcodeDownload/teacherDownload'))

const CourseService = AsyncComponent(()=>import('@/pages/SimpleActivity/CourseService'))
const VipService = AsyncComponent(()=>import('@/pages/SimpleActivity/vipProtocal/index.js'))
const AgreementNew = AsyncComponent(()=>import('@/pages/SimpleActivity/newNameBrand2/Agreement'))
const PrivacyNew = AsyncComponent(()=>import('@/pages/SimpleActivity/newNameBrand2/Privacy'))
const CourseServiceNew = AsyncComponent(()=>import('@/pages/SimpleActivity/newNameBrand2/CourseService'))
const StaticOutSchool = AsyncComponent(()=>import('@/pages/SimpleActivity/staticOutSchool'))
const NewYear = AsyncComponent(()=>import('@/pages/SimpleActivity/newYear2020'))
const ExtrangeCard = AsyncComponent(()=>import('@/pages/SimpleActivity/extrangeCard'))
const ChangePage = AsyncComponent(()=>import('@/pages/SimpleActivity/extrangeCard/exchangePage'))
const NewYearLetterMain = AsyncComponent(()=>import('@/pages/SimpleActivity/newYearLetter/details'));
const NewYearLetter = AsyncComponent(()=>import('@/pages/SimpleActivity/newYearLetter'));


const ClassHourDetails = AsyncComponent(()=>import('@/pages/ClassHourDetails/index'))//系统课详情介绍
const Assessment = AsyncComponent(()=>import('@/pages/Assessment/Assessment'))//测评页面
const Report = AsyncComponent(()=>import('@/pages/Assessment/Report'))//测评报告
const StartAssessment = AsyncComponent(()=>import('@/pages/Assessment/StartAssessment'))//开始测评

const Tecentvcm = AsyncComponent(()=>import('@/pages/Tecentvcm'))//登录验证

const LearningReport = AsyncComponent(()=>import('@/pages/LearningReport'))//学习报告
const LearningReportShareImage = AsyncComponent(()=>import('@/pages/LearningReportShare/LearningReportShareImage'))//学习报告分享图片
const LearningReportShareText = AsyncComponent(()=>import('@/pages/LearningReportShare/LearningReportShareText'))//学习报告分享 文字
const GetExperienceClass = AsyncComponent(()=>import('@/pages/GetExperienceClass'))//体验课领取落地页
const FreeSchoolInfo = AsyncComponent(()=>import('@/pages/GetExperienceClass/schoolInfo'))//校内0元领取后填写信息页

const ClsaaDetailBuy = AsyncComponent(()=>import('@/pages/ClsaaDetailBuy/index'))//系统课详情购买
const UnlockPage = AsyncComponent(()=>import('@/pages/SimpleActivity/UnlockPage'))//如何解锁
const Appointment = AsyncComponent(()=>import('@/pages/SimpleActivity/Appointment'))

const QrcodeDownload =  AsyncComponent(()=> import('@/pages/SimpleActivity/QrcodeDownload'))//二维码下载APP
const JoinAgent = AsyncComponent(()=>import('@/pages/JoinAgent'))//招募代理商
const KindJoinAgent = AsyncComponent(()=>import('@/pages/JoinAgent/kindIndex'))//招募代理商不展示荣怀版本
const SpecialJoinAgent = AsyncComponent(()=>import('@/pages/JoinAgent/specialIndex'))//招募代理商不展示荣怀版本
const TeacherInfo = AsyncComponent(()=>import('@/pages/SimpleActivity/teacherInfo/TeacherInfo')) // 购买成功教师信息以及课程须知页面

const PaymentH5 = AsyncComponent(()=>import('@/pages/PaymentH5/lessonDetail')) //集团学生付费h5系统课详情页
const InnerSchooCOnfirm = AsyncComponent(()=>import('@/pages/PaymentH5/OrderConfirm')) //
const SchoolBuySuccess = AsyncComponent(()=> import('@/pages/PaymentH5/BuySuccess')) //集团学生购买成功后信息填写

const Introduce = AsyncComponent(()=> import('@/pages/SimpleActivity/introduce'))
const PersonInfoAuthor = AsyncComponent(()=> import('@/pages/SimpleActivity/personInfoAuthor'))//个人信息授权与保护声明
const OutSchool = AsyncComponent(()=> import('@/pages/OutSchool/index'))   //校外付费
const OutOrderConfirm = AsyncComponent(()=> import('@/pages/OutSchool/OrderConfirm'))   //校外付费
const AddAdress = AsyncComponent(()=> import('@/pages/OutSchool/AddAdress')) 
const AddressList = AsyncComponent(()=> import('@/pages/OutSchool/AddressList')) 
const EditAddress = AsyncComponent(()=> import('@/pages/OutSchool/EditAddress')) 

const UniqTeacher = AsyncComponent(()=> import('@/pages/SimpleActivity/myUniqTeacher'))

// const TwelveNign = AsyncComponent(()=> import('@/pages/TwelveNign/index'))
const TwelveNign = AsyncComponent(()=> import('@/pages/GetExperienceClass/payment'))
const UnPay = AsyncComponent(()=> import('@/pages/GetExperienceClass/unPay'))
const TwelveConfirm = AsyncComponent(()=> import('@/pages/TwelveNign/confirmOrder'))
const TwelveSuccess = AsyncComponent(()=> import('@/pages/GetExperienceClass/completeInfo'))
const TwelveTeacher = AsyncComponent(()=> import('@/pages/TwelveNign/teacherInfo'))
const PcTwelveNine = AsyncComponent(()=> import('@/pages/TwelveNign/pcPage/index'))
const PcTwelveConfirm = AsyncComponent(()=> import('@/pages/TwelveNign/pcPage/confirmOrder'))

const GetClassMethodByMinute = AsyncComponent(()=> import('@/pages/SimpleActivity/getClassMethodByMinute')) // 一分钟Get上课方法
const ClockIn = AsyncComponent(()=> import('@/pages/SimpleActivity/clockIn/index'))
const WithDrawMoney = AsyncComponent(()=>import('@/pages/SimpleActivity/clockIn/withdrawMoney'))

const VipH5 = AsyncComponent(()=> import('@/pages/VipH5/index')) //精品课h5
const VipConfirm = AsyncComponent(()=> import('@/pages/VipH5/confirmOrder'))
const VipAddressList = AsyncComponent(()=> import('@/pages/VipH5/addressList'))
const VipPayFail = AsyncComponent(()=> import('@/pages/VipH5/unPay'))//vip支付失败页面

const BookLesson = AsyncComponent(()=> import('@/pages/BookLesson/index'))
const WithDrawAddress = AsyncComponent(()=> import('@/pages/SimpleActivity/clockIn/withDrawAddress'))
const HolidayDemo = AsyncComponent(()=> import('@/pages/SimpleActivity/holiday/index'))
const TestReportQM = AsyncComponent(()=>import('@/pages/TobClient/testReportQM'))//测试报告
const TestReportQMSahrePage = AsyncComponent(()=>import('@/pages/TobClient/testReportQM/testReportSharePage'))//测试报告分享页面
const TestReport = AsyncComponent(()=>import('@/pages/TobClient/testReport'))//测试报告
const TestReportSahrePage = AsyncComponent(()=>import('@/pages/TobClient/testReport/testReportSharePage'))//测试报告分享页面

const GardenBuyCourse = AsyncComponent(()=>import('@/pages/TobClient/buyCourse'))//bduan售卖h5
const GardenH5Login = AsyncComponent(()=>import('@/pages/TobClient/buyCourse/h5Login'))//售卖h5登录
const ChooseBaby = AsyncComponent(()=>import('@/pages/TobClient/buyCourse/chooseBaby'))//选择宝宝
const GardenConfirmOrder = AsyncComponent(()=>import('@/pages/TobClient/buyCourse/confirmOrder'))
const GardenBuySuccess = AsyncComponent(()=>import('@/pages/TobClient/buyCourse/buySuccess'))
const PcDownload = AsyncComponent(()=>import('@/pages/SimpleActivity/paradise/pcDownLoad/index'))
const IntroduceLesson  = AsyncComponent(()=>import('@/pages/SimpleActivity/introduceLesson'))


export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/agreement" exact component={Agreement}></Route>
        <Route path="/pictureBook" exact component={PictureBook}></Route>
        <Route path="/gardenPictureBook" exact component={BClientPictureBook}></Route>
        <Route path="/privacy" exact component={Privacy}></Route>
        <Route path="/garden/privacy" exact component={GardenPrivacy}/>
        <Route path="/garden/agreement" exact component={GardenAgreement}/>
        <Route path="/garden/introduce" exact component={GardenIntroduce}/>
        <Route path="/garden/download" exact component={TeacherDownload}/>
        <Route path="/paradise/privacy" exact component={ParadisePrivacy}/>
        <Route path="/paradise/introduce" exact component={ParadiseIntroduce}/>
        <Route path="/paradise/agreement" exact component={ParadiseAgreement}/>
        <Route path="/paradise/download" exact component={StudentDownload}/>
        <Route path="/paradise/mySchool" exact component={MySchool}/>
        
        <Route path="/courseService" exact component={CourseService}></Route>
        <Route path="/vipService" exact component={VipService}/>
        <Route path="/agreement/newBrand" exact component={Agreement}></Route>
        <Route path="/privacy/newBrand" exact component={Privacy}></Route>
        <Route path="/courseService/newBrand" exact component={CourseServiceNew}></Route>      
        <Route path="/newYearAct" exact component={NewYear}></Route>  
        <Route path="/exchangeCard" exact component={ExtrangeCard}/>
        <Route path="/exchangeCard/changePage" exact component={ChangePage}/>
        <Route path="/newYearLetterMain" exact component={NewYearLetterMain} />
        <Route path="/newYearLetter" exact component={NewYearLetter} />

        <Route path="/tecentvcm" exact component={Tecentvcm}></Route>

        <Route path="/learningReport" exact component={LearningReport}></Route>
        <Route path="/learningReportShareImage" exact component={LearningReportShareImage}></Route>
        <Route path="/learningReportShareText" exact component={LearningReportShareText}></Route>
        <Route path="/getExperienceClass" exact component={GetExperienceClass}></Route>

        <Route path="/startAssessment" component={StartAssessment}></Route>
        <Route path="/assessment" exact component={Assessment}></Route>
        <Route path="/report" component={Report}></Route>

        <Route path="/classHourDetails" exact component={ClassHourDetails}></Route>

        <Route path="/clsaaDetailBuy" exact component={ClsaaDetailBuy}></Route>

        <Route path="/experienceClass" exact component={TwelveNign}></Route>

        <Route path="/unlockPage" exact component={UnlockPage}></Route>

        <Route path="/appointment" exact component={Appointment}></Route>
        <Route path="/qrcodeDownload" exact component={QrcodeDownload}></Route>
        {/* <Route path="/marketSpread" exact component={MarketSpread}></Route> */}
        {/* <Route path="/buySuccess" exact component={BuySuccess}></Route> */}
        <Route path="/joinAgent" exact component={JoinAgent}></Route>
        <Route path="/kindJoinAgent" exact component={KindJoinAgent}></Route>
        <Route path="/specialJoinAgent" exact component={SpecialJoinAgent}></Route>
        <Route path="/teacherInfo" exact component={TeacherInfo}></Route>
        <Route path="/paymentH5" exact component={PaymentH5}/>
        <Route path="/paymentH5/orderConfirm" exact component={InnerSchooCOnfirm}/>
        <Route path="/schoolBuySuccess" exact component={SchoolBuySuccess}/>
        <Route path="/introduceGuaGua" exact component={Introduce}/>
        <Route path="/personInfoAuthor" exact component={PersonInfoAuthor}/>
        <Route exact component={FreeSchoolInfo} path="/freeSchoolInfo"/>
        <Route exact path="/uniqTeacher" component={UniqTeacher}/>
        
        <Route exact component={OutSchool} path="/outSchool"/>
        <Route path="/outSchool/outOrderConfirm" component={OutOrderConfirm} exact/>
        <Route path="/outSchool/addAddress" component={AddAdress} exact/>
        <Route path="/outSchool/addressList" component={AddressList} exact/>
        <Route path="/outSchool/editAddress" component={EditAddress} exact/>
        
        <Route path="/twelveNine" component={TwelveNign} exact/>
        <Route path="/twelveNine/unPay" exact component={UnPay}/>
        <Route path="/twelveNine/pcTwelveNine" component={PcTwelveNine} exact/>
        <Route path="/twelveNine/pcTwelveNine/confirmOrder" component={PcTwelveConfirm} exact/>
        <Route path="/twelveNine/confirmOrder" component={TwelveConfirm} exact/>
        <Route path="/twelveNine/twelveSuccess" component={TwelveSuccess} exact/>
        <Route path="/twelveNine/teacherInfo" component={TwelveTeacher} exact/>
        <Route path="/staticOutSchool"  component={StaticOutSchool} exact/>

        <Route path="/getClassMethodByMinute" component={GetClassMethodByMinute} exact/>

        <Route path="/vipH5" component={VipH5} exact/>
        <Route path="/vipH5/orderConfirm" component={VipConfirm} exact/>
        <Route path="/vipH5/addressList" component={VipAddressList} exact/>
        <Route path="/vipH5/unPay" component={VipPayFail} exact/>
        <Route path="/clockIn" component={ClockIn} exact/>
        <Route path="/clockIn/withdraw" component={WithDrawMoney} exact/>
        <Route path="/clockIn/withDrawAddress" component={WithDrawAddress} exact/>
        <Route path="/bookLesson" component={BookLesson} exact/>
        <Route path="/holidayDemo" component={HolidayDemo} exact/>

        <Route path="/testReportQM" component={TestReportQM} exact/>
        <Route path="/testReportQMSharePage" component={TestReportQMSahrePage} exact/>

        <Route path="/testReport" component={TestReport} exact/>
        <Route path="/testReportSharePage" component={TestReportSahrePage} exact/>

        <Route path="/gardenBuyCourse" component={GardenBuyCourse} exact/>
        <Route path="/gardenH5Login" component={GardenH5Login} exact/>
        <Route path="/chooseBaby" component={ChooseBaby} exact/>
        <Route path="/gardenConfirmOrder" component={GardenConfirmOrder} exact/>
        <Route path="/gardenBuySuccess" component={GardenBuySuccess} exact/>
        <Route path="/pcDownload" component={PcDownload} exact/>
        <Route path="/introduceLesson" component={IntroduceLesson} exact/>
      </Switch>
        <SimpleActivityRoute />
        <H5Sell />
        <H5Picture />
    </BrowserRouter>
  );
}