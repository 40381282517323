import {fromJS} from 'immutable'
const defaultState = fromJS({
    userId:''
})

const reducers = {
    SETUSERID(state, userId){
        return state.set('userId', userId)
    }
}

const vipH5Reducer = (state =defaultState, action={})=>{
    const {type, param} = action
    if(reducers[type]){
        return reducers[type](state, param)
    }else{
        return state
    }
}

export default vipH5Reducer