import React, { Component } from 'react';
import './App.css';
import Route from './route.js'

class App extends Component {
  render() {
    return <Route/>
  }
}

export default App;