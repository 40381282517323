import {fromJS} from 'immutable'
const defaultState = fromJS({
    paperRes:{}
})

const reducer = {
    SETPAPERS(state, questions){
        return state.set('paperRes', questions)
    }
}

const assessmentReducer = (state = defaultState, action={})=>{
    const {type, param} = action
    if(reducer[type]){
        return reducer[type](state, param)
    }else{
        return state
    }
    
}
export default assessmentReducer