import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
// import * as request  from "./utils/request";
// import JSBridge from "./utils/JSBridge";
import store from "./store";
import * as serviceWorker from './serviceWorker';
import VConsole from 'vconsole'
//增加可配置入口，默认除线上环境，其余环境都添加vconsole
if(window.location.search.indexOf('showConsole') != -1 || window.location.hostname !== 'web.guaguafeng.com'){
  new VConsole()
}

// React.PureComponent.prototype.$request  = request ;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
